import location from '@assets/icons/AG_location.svg';
import house from '@assets/icons/house.svg';
import user from '@assets/icons/user.svg';
import { useFetchTask } from '@core/http/hooks';
import Page, { PageTab } from '@layout/page/Page';
import { OperatorProfile } from '@modules/auth/model';
import { Dashboard, DashboardEstate } from '@modules/operator/dashboard/model';
import { EstateState, estateStateLabel } from '@shared/modules/estate/model';
import { renderHttpRemoteData } from '@shared/utils/render';
import React, { FC } from 'react';
import { Grid } from 'semantic-ui-react';
import * as Styled from './DashboardPage.styles';
import * as DashboardService from './service';
import { SharedButton } from '@styles/shared';
import { useTabProps } from '@layout/tab/hooks';
import ContentTab from '@layout/tab/ContentTab';

interface DashboardCardProps {
  profile: OperatorProfile;
  estate: DashboardEstate;
}

const DashboardCard: FC<DashboardCardProps> = ({ profile, estate }) => {
  const estateName = estate.name.split(' ').slice(0, 3);

  return (
    <Grid.Column>
      <Styled.DashboardCardContainer to={`/${estate.id}`}>
        <Styled.DashboardCardSegment raised>
          <Styled.DashboardCardContent>
            <Styled.LeftGridColumn>
              <Styled.Letters state={estate.status}>
                {estateName.map(name => name.substring(0, 1).toUpperCase())}
              </Styled.Letters>
            </Styled.LeftGridColumn>
            <Styled.RightGridColumn>
              <Styled.Datas>
                <Styled.Top>
                  <Styled.Name>{estate.name}</Styled.Name>
                  <Styled.State state={estate.status}>{estateStateLabel[estate.status]}</Styled.State>
                </Styled.Top>
                <Styled.Data>
                  <img src={location} alt="City" />
                  <span>{estate.city}</span>
                </Styled.Data>
                <Styled.Data>
                  <img src={house} alt="House" />
                  <span>{estate.unitNumber} logements</span>
                </Styled.Data>
                <Styled.Data>
                  <img src={user} alt="Purchaser" />
                  <span>{estate.estatemateNumber} acquéreurs</span>
                </Styled.Data>
              </Styled.Datas>
            </Styled.RightGridColumn>
          </Styled.DashboardCardContent>
        </Styled.DashboardCardSegment>
      </Styled.DashboardCardContainer>
    </Grid.Column>
  );
};

interface DashboardListProps {
  profile: OperatorProfile;
  dashboard: Dashboard;
  onlyFinished: boolean;
}

const DashboardList: FC<DashboardListProps> = ({ profile, dashboard, onlyFinished }) => {
  const errorList = dashboard.estates.filter(estate => estate.status === EstateState.ERROR);
  const completeList = dashboard.estates.filter(estate => estate.status === EstateState.TO_COMPLETE);
  const submittedList = dashboard.estates.filter(estate => estate.status === EstateState.SUBMITTED);
  const creationList = dashboard.estates.filter(estate => estate.status === EstateState.ASL_CREATION);
  const createdList = dashboard.estates.filter(estate => estate.status === EstateState.ASL_CREATED);
  const finishedList = dashboard.estates.filter(estate => estate.status === EstateState.ASSEMBLY_FINISHED);

  const notFinishedLength =
    errorList.length + completeList.length + submittedList.length + createdList.length + finishedList.length;
  const finishedLength = finishedList.length;

  return (
    <Grid columns={2}>
      {!onlyFinished && (
        <>
          {notFinishedLength > 0 ? (
            <>
              {!onlyFinished &&
                errorList.map(estate => (
                  <DashboardCard key={`${estate.id}/${estate.status}`} profile={profile} estate={estate} />
                ))}
              {!onlyFinished &&
                completeList.map(estate => (
                  <DashboardCard key={`${estate.id}/${estate.status}`} profile={profile} estate={estate} />
                ))}
              {!onlyFinished &&
                submittedList.map(estate => (
                  <DashboardCard key={`${estate.id}/${estate.status}`} profile={profile} estate={estate} />
                ))}
              {!onlyFinished &&
                creationList.map(estate => (
                  <DashboardCard key={`${estate.id}/${estate.status}`} profile={profile} estate={estate} />
                ))}
              {!onlyFinished &&
                createdList.map(estate => (
                  <DashboardCard key={`${estate.id}/${estate.status}`} profile={profile} estate={estate} />
                ))}
            </>
          ) : (
            <Styled.NoDataCard>
              <p>Aucune donnée à afficher.</p>
            </Styled.NoDataCard>
          )}
        </>
      )}
      {onlyFinished && (
        <>
          {finishedLength > 0 ? (
            finishedList.map(estate => (
              <DashboardCard key={`${estate.id}/${estate.status}`} profile={profile} estate={estate} />
            ))
          ) : (
            <Styled.NoDataCard>
              <p>Aucune donnée à afficher.</p>
            </Styled.NoDataCard>
          )}
        </>
      )}
    </Grid>
  );
};

interface DashboardOperatorProps {
  profile: OperatorProfile;
  onlyFinished: boolean;
}

const DashboardOperator: FC<DashboardOperatorProps> = ({ profile, onlyFinished }) => {
  const [dashboard] = useFetchTask(DashboardService.getDashboard);

  const tabs: Array<PageTab> = [
    { title: 'Opérations en cours', to: `/`, exact: true },
    { title: 'Opérations terminées', to: `/finished`, exact: true },
  ];

  const { thumbProps } = useTabProps({
    tabs: tabs,
  });

  return (
    <Page>
      <Styled.Title>Bonjour {profile.firstName} !</Styled.Title>

      <Styled.Message>
        Bienvenue sur votre espace ASL Community, nous sommes à votre disposition pour vous accompagner dans la création
        des ASL de vos opérations immobilières.
      </Styled.Message>

      <Styled.SubTitle>Mes opérations immobilières</Styled.SubTitle>

      <ContentTab {...thumbProps}>
        <Styled.TopBar>
          <div>
            <Styled.TopBarRight key="top-right">
              <SharedButton
                key="new-asl-form"
                as="a"
                secondary
                content="Demande de création d'une ASL"
                icon="add"
                href={profile.aslCreationFormLink}
                target="_blank"
                rel="noreferrer noopener"
                btnType="save"
              />
            </Styled.TopBarRight>
          </div>
        </Styled.TopBar>
        {renderHttpRemoteData(dashboard, dashboard => (
          <DashboardList profile={profile} dashboard={dashboard} onlyFinished={onlyFinished} />
        ))}
      </ContentTab>
    </Page>
  );
};

export default DashboardOperator;
