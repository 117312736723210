import { constVoid, pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import React, { FC, ReactNode } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import {
  Breadcrumb as SemanticBreadcrumb,
  BreadcrumbSectionProps,
  Message,
  SemanticCOLORS,
  MessageHeader,
  MessageContent,
  Icon,
  SemanticICONS,
} from 'semantic-ui-react';

import { DebouncedLineLoader } from '@layout/loaders/line-loader/LineLoader';
import PageSteps from '@layout/page/steps/PageSteps';
import Seo from '@shared/modules/seo/Seo';
import { renderOptional } from '@shared/utils/render';

import * as Styled from './Page.styles';

export const PAGE_SCROLLER_ID = 'page-scroller';

export interface PageTab {
  title: string;
  to: string;
  exact?: boolean;
  isHide?: boolean;
}

export interface Step {
  title: string;
  to: string;
  exact?: boolean;
  stepValueClick: string;
  stepValueCheck: string;
}

export interface Stepper {
  stateIndexRecord: Record<any, number>; // type enum
  currentState: string;
  steps: Array<Step>;
}

export interface MessageProps {
  title?: string;
  description?: string;
  icon?: SemanticICONS;
  color?: SemanticCOLORS;
}

export interface Breadcrumb {
  title: ReactNode;
  displayTitle?: string;
  to?: string;
  navBack?: boolean;
  hideChevron?: boolean;
}

export interface BottomBarProps {
  left?: ReactNode;
  right?: ReactNode;
}

export interface PageProps {
  breadcrumbs?: Breadcrumb | Array<Breadcrumb>;
  tabs?: Array<PageTab>;
  stepper?: Stepper;
  bottom?: BottomBarProps;
  loading?: boolean;
  state?: ReactNode;
  message?: MessageProps;
}

export interface ThumbProps {
  tabs?: Array<PageTab>;
  state?: ReactNode;
  message?: MessageProps;
}

const PageTabs: FC<{ tabs?: Array<PageTab> }> = ({ tabs }) => {
  if (tabs && tabs.length) {
    const panes = tabs
      .filter(tab => !tab.isHide)
      .map(tab => ({
        menuItem: {
          key: tab.to,
          as: NavLink,
          to: tab.to,
          exact: tab.exact,
          strict: true,
          content: tab.title,
          activeClassName: 'active',
          active: false,
        },
      }));

    const menu = { secondary: true, pointing: true };

    return <Styled.PageTabs menu={menu} panes={panes} />;
  }

  return null;
};

const PageStepper: FC<{ stepper?: Stepper }> = ({ stepper }) => {
  if (stepper) {
    return <PageSteps stepper={stepper} />;
  }

  return null;
};

const PageBottomBar: FC<{ bottom?: BottomBarProps }> = ({ bottom }) => {
  const optBottom = O.fromNullable(bottom);

  const leftContent = pipe(
    optBottom,
    O.chainNullableK(b => b.left),
  );

  const rightContent = pipe(
    optBottom,
    O.chainNullableK(b => b.right),
  );

  if (O.isSome(leftContent) || O.isSome(rightContent)) {
    return (
      <Styled.PageBottomBar>
        <div>
          <Styled.PageBottomBarLeft key="page-bottom-left">{O.toNullable(leftContent)}</Styled.PageBottomBarLeft>
          <Styled.PageBottomBarRight key="page-bottom-right">{O.toNullable(rightContent)}</Styled.PageBottomBarRight>
        </div>
      </Styled.PageBottomBar>
    );
  }

  return null;
};

const Page: FC<PageProps> = ({ breadcrumbs, tabs, stepper, bottom, loading, state, children, message }) => {
  const isLoading = loading || children == null;
  const history = useHistory();

  const breadcrumbsOpt = pipe(
    O.fromNullable(breadcrumbs),
    O.map(breadcrumbs => (Array.isArray(breadcrumbs) ? breadcrumbs : [breadcrumbs])),
  );

  const seoTitle: string | undefined = pipe(
    breadcrumbsOpt,
    O.map(breadcrumbs => breadcrumbs.map(b => b.displayTitle ?? b.title).join(' - ')),
    O.toUndefined,
  );

  const breadcrumbSections: O.Option<Array<BreadcrumbSectionProps>> = pipe(
    breadcrumbsOpt,
    O.map(breadcrumbs =>
      breadcrumbs.map<BreadcrumbSectionProps>((b, i) => ({
        key: i,
        content: b.title,
        as: b.to ? Link : undefined,
        to: b.to,
        onClick: b.navBack ? history.goBack : constVoid,
        active: i === breadcrumbs.length - 1,
      })),
    ),
  );

  return (
    <Styled.PageContainer>
      <Seo title={seoTitle} />

      {isLoading && <DebouncedLineLoader />}

      <Styled.PageContentWrapper id={PAGE_SCROLLER_ID}>
        <Styled.PageContent>
          {renderOptional(breadcrumbSections, bs => (
            <Styled.PageBreadCrumbContainer>
              <SemanticBreadcrumb icon="chevron right" sections={bs} />
              {state}
            </Styled.PageBreadCrumbContainer>
          ))}

          <PageStepper stepper={stepper} />
          <PageTabs tabs={tabs} />
          <div>
            {message && (
              <Message color={message.color} icon={message?.icon !== undefined}>
                {message?.icon && <Icon name={message?.icon} />}
                <MessageContent>
                  {message?.title && <MessageHeader>{message.title}</MessageHeader>}
                  {message?.description && <p>{message.description}</p>}
                </MessageContent>
              </Message>
            )}
          </div>

          <Styled.PageChildren>{children}</Styled.PageChildren>
        </Styled.PageContent>
      </Styled.PageContentWrapper>

      <PageBottomBar bottom={bottom} />
    </Styled.PageContainer>
  );
};

export default Page;
