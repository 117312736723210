import { useCallback, useState } from 'react';
import { ContentTabProps } from './ContentTab';

export function useTabProps(props?: ContentTabProps) {
  const [thumbProps, setThumbProps] = useState<ContentTabProps>(() => props ?? {});

  const updateThumbProps = useCallback((props: ContentTabProps) => setThumbProps(old => ({ ...old, ...props })), []);

  return {
    thumbProps,
    updateThumbProps,
  };
}
