import { Routes, safeLazy } from '@core/router';
import { OperatorProfile } from '@modules/auth/model';
import React, { FC } from 'react';
import { Route } from 'react-router-dom';
import DashboardPage from './dashboard/DashboardPage';

const DashboardRoutes = safeLazy(() => import('./dashboard/routes'));
const ProfileComponent = safeLazy(() => import('./profile/DetailPage'));
const EstateComponent = safeLazy(() => import('./estate/Estate'));

interface OperatorRoutesProps {
  profile: OperatorProfile;
}

const OperatorRoutes: FC<OperatorRoutesProps> = ({ profile }) => (
  <Routes>
    <Route path="/profile">
      <ProfileComponent profile={profile} />
    </Route>
    <Route path="/finished">
      <DashboardPage profile={profile} onlyFinished />
    </Route>
    <Route path="/" exact>
      <DashboardRoutes profile={profile} />
    </Route>
    <Route path="/:id" component={EstateComponent} />
  </Routes>
);

export default OperatorRoutes;
