import { httpService, HttpTask } from '@core/http';
import {
  ActivationDetail,
  ActivationParams,
  ActivationToken,
  AttendanceInformation,
  AttendanceParams,
  AttendanceSuccess,
  AuthenticateParams,
  ForgotPasswordParams,
  Profile,
  ResetPasswordParams,
  ResetPasswordToken,
  ServerProfile,
} from '@modules/auth/model';
import { UpdateUserEmailParams, UpdateUserPasswordParams, UserRole } from '@shared/modules/users/model';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import * as O from 'fp-ts/Option';
import * as T from 'fp-ts/Task';

import * as ReferentialService from '@shared/modules/referential/service';
import * as EstatemateService from '@modules/estatemate/service';

import { Tutorial } from '@modules/admin/parameters/tutorials/model';
import { sequenceS } from 'fp-ts/Apply';
import { AssemblyId } from '@modules/board/assembly/model';

export function getProfile(): HttpTask<Profile> {
  const getTutorialLinks = (profile: ServerProfile): T.Task<Array<Tutorial>> =>
    pipe(
      profile,
      O.fromPredicate(profile => UserRole.Operator !== profile.role),
      O.fold(
        () => T.of([]),
        () =>
          pipe(
            ReferentialService.getTutorials(),
            TE.getOrElse(() => T.of<Array<Tutorial>>([])),
          ),
      ),
    );

  const getBioActivation = (profile: ServerProfile): T.Task<boolean> =>
    pipe(
      profile,
      O.fromPredicate(profile => profile.role === UserRole.Estatemate || profile.role === UserRole.Board),
      O.fold(
        () => T.of(false),
        () =>
          pipe(
            EstatemateService.getBioActivated(),
            TE.fold(
              () => T.of(false),
              ({ isBioActivated }) => T.of(isBioActivated),
            ),
          ),
      ),
    );

  return pipe(
    TE.Do,
    TE.bind('profile', () => httpService.get<ServerProfile>('/profile')),
    TE.bind('related', ({ profile }) =>
      pipe(
        sequenceS(TE.ApplyPar)({
          tutorials: TE.rightTask(getTutorialLinks(profile)),
          bioActivation: TE.rightTask(getBioActivation(profile)),
        }),
      ),
    ),
    TE.map(({ profile, related }) => ({
      ...profile,
      tutorials: related.tutorials,
      bioActivation: related.bioActivation,
    })),
  );
}

export function authenticate(params: AuthenticateParams): HttpTask<Profile> {
  return pipe(httpService.post('/authenticate', params), TE.chain(getProfile));
}

export function logout(): HttpTask {
  return httpService.post('/logout');
}

export function forgotPassword(params: ForgotPasswordParams): HttpTask {
  return httpService.post('/password-reset/forgot', params);
}

export function getPasswordResetDetail(token: ResetPasswordToken): HttpTask<ForgotPasswordParams> {
  return httpService.get(`/password-reset/${token}`);
}

export function resetPassword(token: ResetPasswordToken, params: ResetPasswordParams): HttpTask {
  return httpService.post(`/password-reset/${token}`, params);
}

export function getActivationDetail(token: ActivationToken): HttpTask<ActivationDetail> {
  return httpService.get(`/activation/${token}`);
}

export function activateAccount(token: ActivationToken, params: ActivationParams): HttpTask<Profile> {
  return pipe(httpService.post(`/activation/${token}`, params), TE.chain(getProfile));
}

export function updateProfileEmail(params: UpdateUserEmailParams): HttpTask {
  return httpService.post('/profile/change-email', params);
}

export function updateProfilePassword(params: UpdateUserPasswordParams): HttpTask {
  return httpService.post('/profile/change-password', params);
}

export function getAttendance(id: AssemblyId): HttpTask<AttendanceInformation> {
  return httpService.get(`/attendance/${id}`);
}
export function postAttendance(id: AssemblyId, params: AttendanceParams): HttpTask<AttendanceSuccess> {
  return pipe(httpService.post(`/attendance/${id}`, params));
}
