import { Link } from 'react-router-dom';
import { Segment } from 'semantic-ui-react';
import styled from 'styled-components';

import { EstateState } from '@shared/modules/estate/model';
import { backgroundColor } from '@styles/utils';

export const DashboardCardContainer = styled(Link)`
  color: black;

  &:hover {
    color: black;
  }
`;

export const DashboardCardSegment = styled(Segment)`
  height: 100%;
  padding: 0 !important;
`;

export const DashboardCardContent = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 20% 80%;
`;

export const Title = styled.p`
  font-size: 33px;
  font-weight: 600;
  color: ${backgroundColor(900)};
`;

export const Message = styled.p`
  font-size: 16px;
  font-weight: 500;
`;

export const SubTitle = styled.p`
  padding-top: 20px;
  font-size: 20px;
  font-weight: 600;
`;

export const LeftGridColumn = styled.div`
  padding: 0 !important;
  color: #ffffff;
`;

export const RightGridColumn = styled.div`
  padding: 10px;
`;

export const Letters = styled.p<{ state: EstateState }>`
  font-size: 33px;
  font-weight: 500;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 0 0 10px;
  background: ${props => operatorEstateStateColors[props.state]};
`;

export const Datas = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Name = styled.p`
  margin: 0;
  font-size: 22px;
`;

export const State = styled.div<{ state: EstateState }>`
  padding: 5px 15px;
  border-radius: 20px;
  background: ${props => operatorEstateStateColors[props.state]};
  color: #ffffff;
`;

export const Data = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  font-size: 14px;

  span {
    padding-left: 5px;
  }
`;

export const operatorEstateStateColors: Record<EstateState, string> = {
  [EstateState.ERROR]: '#f35858',
  [EstateState.TO_COMPLETE]: '#fab710',
  [EstateState.SUBMITTED]: '#02b885',
  [EstateState.ASL_CREATION]: '#02b885',
  [EstateState.ORGANIZE_ASSEMBLY]: '#02b885',
  [EstateState.PREPARE_REPORT]: '#02b885',
  [EstateState.SUMMON_SENT]: '#02b885',
  [EstateState.REPORT_SENT]: '#017353',
  [EstateState.ASL_ACTIVATED]: '#017353',
  [EstateState.SUSPENDED]: '#d6d6d8',
  [EstateState.ASL_CREATED]: '#00503a',
  [EstateState.ASSEMBLY_FINISHED]: '#343D6F',
};

export const TopBar = styled.div`
  > div {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: ${props => props.theme.breakpoints.large + 40}px;
    padding: 0 15px;

    .button {
      margin-right: 7px !important;
    }
  }
`;

export const TopBarRight = styled.div`
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  padding: 7px 0;
`;

export const NoDataCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
