import { Newtype } from 'newtype-ts';

import { Address } from '@shared/model/address';
import { SearchFilter } from '@shared/modules/filter';
import { EquipmentId } from '@shared/modules/referential/model';

import { OperatorId, OperatorRange } from '../operator/model';
import { AssemblyId } from '@modules/board/assembly/model';
import { SubscriptionFrequencyParamType } from './steps/5-pv/subscriptions/model';
import { ComputationType } from '../finance/monitoring/model';

export type EstateId = Newtype<{ readonly ID: unique symbol }, string> & string;

export enum EstateState {
  ERROR = '1_ERROR',
  TO_COMPLETE = '2_TO_COMPLETE',
  SUBMITTED = '3_SUBMITTED',
  ASL_CREATION = '4_ASL_CREATION',
  ORGANIZE_ASSEMBLY = '5_ORGANIZE_ASSEMBLY',
  SUMMON_SENT = '7_SUMMON_SENT',
  PREPARE_REPORT = '6_PREPARE_REPORT',
  REPORT_SENT = '8_REPORT_SENT',
  ASL_ACTIVATED = '8_ACTIVATED',
  SUSPENDED = '9_SUSPENDED',
  ASL_CREATED = '10_ASL_CREATED',
  ASSEMBLY_FINISHED = '11_ASSEMBLY_FINISHED',
}

export const estateStateLabel: Record<EstateState, string> = {
  [EstateState.ERROR]: 'Erreur',
  [EstateState.TO_COMPLETE]: 'A compléter',
  [EstateState.SUBMITTED]: 'Soumis',
  [EstateState.ASL_CREATION]: 'ASL en cours de création',
  [EstateState.ORGANIZE_ASSEMBLY]: '1er AG à organiser',
  [EstateState.SUMMON_SENT]: 'Convocation envoyée',
  [EstateState.PREPARE_REPORT]: 'PV à préparer',
  [EstateState.REPORT_SENT]: 'PV envoyé',
  [EstateState.ASL_ACTIVATED]: 'Activé',
  [EstateState.SUSPENDED]: 'Suspendu',
  [EstateState.ASL_CREATED]: 'ASL créée',
  [EstateState.ASSEMBLY_FINISHED]: 'AG terminée',
};

export const estateStateIndex: Record<EstateState, number> = {
  [EstateState.SUSPENDED]: 0,
  [EstateState.ERROR]: 1,
  [EstateState.TO_COMPLETE]: 2,
  [EstateState.SUBMITTED]: 3,
  [EstateState.ASL_CREATION]: 4,
  [EstateState.ORGANIZE_ASSEMBLY]: 5,
  [EstateState.SUMMON_SENT]: 6,
  [EstateState.PREPARE_REPORT]: 7,
  [EstateState.REPORT_SENT]: 8,
  [EstateState.ASL_ACTIVATED]: 8,
  [EstateState.ASL_CREATED]: 9,
  [EstateState.ASSEMBLY_FINISHED]: 10,
};

export const estateStateRoute: Record<EstateState, string> = {
  [EstateState.SUSPENDED]: '',
  [EstateState.ERROR]: '',
  [EstateState.TO_COMPLETE]: '',
  [EstateState.SUBMITTED]: '',
  [EstateState.ASL_CREATION]: '/asl-creation',
  [EstateState.ORGANIZE_ASSEMBLY]: '/summons',
  [EstateState.SUMMON_SENT]: '/assembly',
  [EstateState.PREPARE_REPORT]: '/report',
  [EstateState.ASL_ACTIVATED]: '',
  [EstateState.REPORT_SENT]: '',
  [EstateState.ASL_CREATED]: '/report',
  [EstateState.ASSEMBLY_FINISHED]: '/report',
};

export enum EstateType {
  Basic = 'BASIC',
  Simplified = 'SIMPLIFIED',
}

export const estateTypeLabel: Record<EstateType, string> = {
  [EstateType.Basic]: 'Classique',
  [EstateType.Simplified]: 'Simplifié',
};

export const estateSelectTypeLabel: Record<EstateType, string> = {
  [EstateType.Basic]: 'Classique (avec gestion de la première AG)',
  [EstateType.Simplified]: 'Simplifié (sans gestion de la première AG)',
};

export interface Estate {
  id: EstateId;
  name: string;
  operatorLabel?: string;
  missionDate: string | null;
  status: EstateState;
  type: EstateType;
  mondayId: string;
}

export interface CreateEstateBase<T extends EstateType> {
  name: string;
  address: Address;
  unitNumber: string;
  type: T;
  mondayId: string;
}

export interface CreateEstateBasic extends CreateEstateBase<EstateType.Basic> {
  operator: OperatorRange;
}

export interface CreateEstateSimplified extends CreateEstateBase<EstateType.Simplified> {}

export type CreateEstate = CreateEstateBasic | CreateEstateSimplified;

export type CreateEstateOperator = Omit<CreateEstate, 'operator'>;

export interface CreateEstateParamsBase<T extends EstateType> {
  name: string;
  address: Address;
  unitNumber: string;
  type: T;
}

export interface CreateEstateBasicParams extends CreateEstateParamsBase<EstateType.Basic> {
  operatorId: OperatorId;
}

export interface CreateEstateSimplifiedParams extends CreateEstateParamsBase<EstateType.Simplified> {}

export type CreateEstateParams = CreateEstateBasicParams | CreateEstateSimplifiedParams;

export interface EstateRangeFilter extends SearchFilter {
  operators: Array<OperatorId> | null;
  statuses: Array<EstateState> | null;
  types: EstateType | null;
}

export const defaultEstateRangeFilter: EstateRangeFilter = {
  search: null,
  operators: null,
  statuses: null,
  types: null,
};

export type EstateAction =
  | 'SUBMIT'
  | 'SUSPEND'
  | 'VALIDATE_SUBMISSION'
  | 'REFUSE_SUBMISSION'
  | 'CLOSE_VOTES'
  | 'SEND_SUMMONS'
  | 'CANCEL_REPORTS'
  | 'CANCEL_SUMMONS'
  | 'CLOSE_ATTENDANCES'
  | 'CREATE_ASL'
  | 'UNSUSPEND'
  | 'SEND_REPORTS'
  | 'SKIP_TO_SUBSCRIPTION_PAGE'
  | 'OVERRIDE_ASSEMBLY_DATE'
  | 'OVERRIDE_CLOSE_ASSEMBLY_DATE'
  | 'CANCEL_ASSEMBLY';

export interface EstateDetail {
  id: EstateId;
  name: string;
  status: EstateState;
  actions: Array<EstateAction>;
  currentAssemblyId: AssemblyId;
  currentAssemblyStatus: string;
  currentAssemblyDate: string;
  currentAssemblySubscriptionClosed: boolean;
  subscriptionPartitionFrequency: SubscriptionFrequencyParamType;
  type: EstateType;
  currentAssemblyIsFirst: Boolean;
  skippedCurrentAssemblyToSubscriptionStep: boolean;
  mondayId?: string | null;
}
export interface EstateStepGeneral {
  name: string;
  missionDate?: string | null;
  unitNumber: string;
  address: Address;
  operatorId: OperatorId;
  equipmentIds?: Array<EquipmentId>;
  otherEquipment?: string | null;
  hasHenhouse: boolean;
  hasGarden: boolean;
  mondayId?: string | null;
  boardContactEmail?: string | null;
  workingCapitalType?: ComputationType;
  workingCapitalWithoutMacroLot?: number;
}

export type EstateBoardGeneral = Omit<
  EstateStepGeneral,
  | 'missionDate'
  | 'unitNumber'
  | 'operatorId'
  | 'equipmentIds'
  | 'otherEquipment'
  | 'hasHenhouse'
  | 'hasGarden'
  | 'mondayId'
  | 'workingCapitalType'
  | 'workingCapitalWithoutMacroLot'
>;
