import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { DebouncedLineLoader } from '@layout/loaders/line-loader/LineLoader';
import * as Styled from './ContentTab.styles';

export const PAGE_SCROLLER_ID = 'page-scroller';

export interface ContentTab {
  title: string;
  to: string;
  exact?: boolean;
  isHide?: boolean;
}

export interface ContentTabProps {
  tabs?: Array<ContentTab>;
}

const ContentTabTabs: FC<{ tabs?: Array<ContentTab> }> = ({ tabs }) => {
  if (tabs && tabs.length) {
    const panes = tabs
      .filter(tab => !tab.isHide)
      .map(tab => ({
        menuItem: {
          key: tab.to,
          as: NavLink,
          to: tab.to,
          exact: tab.exact,
          strict: true,
          content: tab.title,
          activeClassName: 'active',
          active: false,
        },
      }));

    const menu = { secondary: true, pointing: true };

    return <Styled.ContentTabTabs menu={menu} panes={panes} />;
  }

  return null;
};

const ContentTabComponent: FC<ContentTabProps> = ({ tabs, children }) => {
  const isLoading = children == null;

  return (
    <Styled.ContentTabContainer>
      {isLoading && <DebouncedLineLoader />}
      <Styled.ContentTabContentWrapper id={PAGE_SCROLLER_ID}>
        <Styled.ContentTabContent>
          <ContentTabTabs tabs={tabs} />
          <Styled.ContentTabChildren>{children}</Styled.ContentTabChildren>
        </Styled.ContentTabContent>
      </Styled.ContentTabContentWrapper>
    </Styled.ContentTabContainer>
  );
};

export default ContentTabComponent;
