import { defaultAxiosInstance } from '@core/http/config';
import { HttpError, HttpRange, HttpStatusCode, HttpTask } from '@core/http/model';
import { Filter } from '@shared/modules/filter';
import { Range, RangeCursor, RangeResult } from '@shared/modules/range';
import { logSentryHttpError } from '@shared/modules/sentry/utils';
import { removeEmptyString } from '@shared/utils/string';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import * as B from 'fp-ts/boolean';
import { Lazy, pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as TE from 'fp-ts/TaskEither';

function onError<E = unknown>(err: unknown): HttpError<E> {
  const error = HttpError.fromAxiosError<E>(err as AxiosError);

  if (
    error.status >= 400 &&
    ![
      HttpStatusCode.UNAUTHORIZED,
      HttpStatusCode.FORBIDDEN,
      HttpStatusCode.NOT_FOUND,
      HttpStatusCode.CONFLICT,
      HttpStatusCode.EXPECTATION_FAILED,
    ].includes(error.status)
  ) {
    logSentryHttpError(
      `[http] error ${error.status} on ${O.getOrElse(() => 'unknown')(error.url)} path`,
      error,
      undefined,
      { err },
    );
  }

  return error;
}

function transformRequest<R, E>(
  request: Lazy<Promise<AxiosResponse<R>>>,
  raw?: true,
): HttpTask<R | AxiosResponse<R>, E> {
  return pipe(
    TE.tryCatch(request, err => onError<E>(err)),
    TE.map(res => (raw ? res : res.data)),
  );
}

function get<R = unknown, E = unknown>(url: string, config?: AxiosRequestConfig): HttpTask<R, E>;
function get<R = unknown, E = unknown>(
  url: string,
  config: AxiosRequestConfig,
  raw: true,
): HttpTask<AxiosResponse<R>, E>;

function get<R = unknown, E = unknown>(
  url: string,
  config?: AxiosRequestConfig,
  raw?: true,
): HttpTask<R | AxiosResponse<R>, E> {
  return transformRequest(() => defaultAxiosInstance.get(url, config), raw);
}

function getRange<R = unknown, F extends Filter = {}, E = unknown>(
  url: string,
  cursor: RangeCursor,
  filter?: F,
  config?: AxiosRequestConfig,
): HttpRange<R, F, E> {
  return pipe(
    get<RangeResult<R, F>, E>(url, {
      ...config,
      params: {
        ...config?.params,
        ...cursor,
        ...filter,
      },
    }),
    TE.map(Range.fromRangeResult),
  );
}

function post<R = unknown, E = unknown>(url: string, data?: any, config?: AxiosRequestConfig): HttpTask<R, E>;
function post<R = unknown, E = unknown>(
  url: string,
  data: any,
  config: AxiosRequestConfig,
  raw: true,
): HttpTask<AxiosResponse<R>, E>;

function post<R = unknown, E = unknown>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
  raw?: true,
): HttpTask<R | AxiosResponse<R>, E> {
  const dataStringToNullable = pipe(
    data instanceof FormData,
    B.fold(
      () =>
        pipe(
          O.fromNullable(data),
          O.fold(
            () => data,
            data => removeEmptyString(data),
          ),
        ),
      () => data,
    ),
  );

  return transformRequest(() => defaultAxiosInstance.post(url, dataStringToNullable, config), raw);
}

function del<R = unknown, E = unknown>(url: string, config?: AxiosRequestConfig): HttpTask<R, E>;
function del<R = unknown, E = unknown>(
  url: string,
  config: AxiosRequestConfig,
  raw: true,
): HttpTask<R | AxiosResponse<R>, E>;

function del<R = unknown, E = unknown>(
  url: string,
  config?: AxiosRequestConfig,
  raw?: true,
): HttpTask<R | AxiosResponse<R>, E> {
  return transformRequest(() => defaultAxiosInstance.delete(url, config), raw);
}

export const httpService = {
  get,
  getRange,
  post,
  delete: del,
};
